<template>
    <div id="main-header">
        <!--header-->
        <section class="sz-introbg">
            <div v-if="currentEnvironment" class="notification-block notification-block-top">
                <div role="alert" aria-live="polite" aria-atomic="true" class="alert alert-primary alert-dismissible">
                    <div class="scroll-left">
                        <p>This is Test Environment !</p>
                    </div>
                </div>
            </div>
            <div class="sz-introbg-container">
                <!-- <div id="google_translate_element_id"></div> -->
                <div class="sz-introbg-top">
                    <div class="logo">
                        <router-link v-bind:to="{ name: 'Home'}">
                            <!-- <img src="/static/images/logo.png" alt="Velocity Vehicle Group"> -->
                            <picture>
                              <source type="image/webp" srcset="/static/images/logo.webp">
                              <source type="image/png" srcset="/static/images/logo.png">
                              <img src="/static/images/logo.png" alt="Velocity Vehicle Group" width="335" height="66">
                            </picture>
                        </router-link>
                    </div>
                    <div class="sz-introbg-right">
                        <div class="customsearch" style="display: none;">
                            <div class="form-group has-search">
                                <span class="fa fa-search form-control-feedback"></span>
                                <input type="text" class="form-control" placeholder="Search here...">
                            </div>
                        </div>
                        <div class="link-block covid-link-block">
                            <router-link v-bind:to="{path: 'cpr-training-event-calendar'}">
                                <!-- <img class="linkimg" src="/static/images/covid-19.png"> -->
                                <div :data-title="staticLanguageMessages.front_label_vvg_first_aid_crp_training_center" class="SectionName">
                                    <h1>{{staticLanguageMessages.front_label_vvg_first_aid_crp_training_center}}</h1>
                                </div>
                            </router-link>
                        </div>
                        <div class="menucontent pull-right">
                            <div class="button_container" id="toggle"><span class="top"></span><span class="middle"></span><span class="bottom"></span></div>
                            <div class="account_login_button desktop-content" >
                                <router-link v-show="roleId == 1 && isLoggedIn" v-bind:to="{name: 'ListUser'}" class="ac-left-des"><i class="fa fa-user" aria-hidden="true"></i></router-link>
                                <router-link v-show="roleId == 2 && isLoggedIn" v-bind:to="{name: 'ListEvent'}" class="ac-left-des"><i class="fa fa-user" aria-hidden="true"></i></router-link>
                                <router-link v-bind:to="{name: 'Forum'}" :title="staticLanguageMessages.front_tool_tip_label_forum" class="ac-left-des"><i class="fa fa-comments-o" aria-hidden="true"></i></router-link>
                                <router-link v-show="showEnvironmentalButton" v-bind:to="{name: 'AddEnvironmental'}" :title="staticLanguageMessages.front_tool_tip_label_environmental" class="ac-left-des">
                                    <i class="fa fa-plus" aria-hidden="true"></i>
                                </router-link>
                                <!-- <router-link v-show="roleId == 2 && isLoggedIn" v-bind:to="{name: 'ListEvent'}" class="ac-left-des"><i class="fa fa-user" aria-hidden="true"></i></router-link> -->

                                <a v-if="isLoggedIn" href="#" :title="staticLanguageMessages.front_tool_tip_label_logout" v-on:click.prevent="logout()"><i class="fa fa-sign-out" aria-hidden="true"></i></a>
                                <a v-if="!isLoggedIn" href="#" :title="staticLanguageMessages.front_label_login" v-on:click.prevent="login()"><i class="fa fa-sign-in" aria-hidden="true"></i></a>

                                <a v-show="roleId == 1" v-bind:class="{ active: enIsActiveLang }" href="#" title="English" v-on:click.prevent="languageChange('en')" style="font-size: 25px;color: #fff">EN</a>
                                <a v-show="roleId == 1" style="font-size: 23px;color: #fff;margin-left:5px;">|</a>
                                <a v-show="roleId == 1" v-bind:class="{ active: esIsActiveLang }" href="#" title="Spanish" v-on:click.prevent="languageChange('es')" style="font-size: 25px;margin-left:5px;color: #fff">ES</a>
                            </div>
                            <div class="mobile-content">
                              <div class="dropdown">
                                <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                  <i class="fa fa-ellipsis-v"></i>
                                </button>
                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                  <li v-if="roleId == 1 && isLoggedIn"><router-link  v-bind:to="{name: 'ListUser'}" class="ac-left-des"><i class="fa fa-user" aria-hidden="true"></i>{{ staticLanguageMessages.label_list_user }}</router-link></li>
                                  <li v-if="roleId == 2 && isLoggedIn"><router-link  v-bind:to="{name: 'ListEvent'}" class="ac-left-des"><i class="fa fa-user" aria-hidden="true"></i>{{ staticLanguageMessages.label_list_user }}</router-link></li>
                                  <li><router-link v-bind:to="{name: 'Forum'}" :title="staticLanguageMessages.front_tool_tip_label_forum" class="ac-left-des"><i class="fa fa-comments-o" aria-hidden="true">
                                  {{ staticLanguageMessages.front_tool_tip_label_forum }}</i></router-link></li>
                                  <li v-show="showEnvironmentalButton"><router-link  v-bind:to="{name: 'AddEnvironmental'}" :title="staticLanguageMessages.front_tool_tip_label_environmental" class="ac-left-des">
                                      <i class="fa fa-plus" aria-hidden="true">
                                      {{ staticLanguageMessages.front_tool_tip_label_environmental }}</i>
                                  </router-link></li>
                                  <li v-if="isLoggedIn"><a  href="#" :title="staticLanguageMessages.front_tool_tip_label_logout" v-on:click.prevent="logout()"><i class="fa fa-sign-out" aria-hidden="true">
                                  {{ staticLanguageMessages.front_tool_tip_label_logout }}</i></a></li>
                                  <li v-if="!isLoggedIn"><a  href="#" :title="staticLanguageMessages.front_label_login" v-on:click.prevent="login()"><i class="fa fa-sign-in" aria-hidden="true"></i></a></li>
                                </ul>
                              </div>
                            </div>
                            <div class="mobile-content language-link">
                                <a v-bind:class="{ active: enIsActiveLang }" href="#" title="English" v-on:click.prevent="languageChange('en')" style="font-size: 25px;color: #fff">EN</a>
                                <a class="divider" style="font-size: 25px;color: #fff;">|</a>
                                <a v-bind:class="{ active: esIsActiveLang }" href="#" title="Spanish" v-on:click.prevent="languageChange('es')" style="font-size: 25px;color: #fff">ES</a>
                            </div>
                            <div class="overlay" id="overlay">
                                <div class="MenuVerticalTabContainer">
                                    <div class="tab">
                                        <button class="tablinks" onclick="openDirectLink('/');" id="defaultOpen">
                                            <!--<button class="tablinks" v-on:click.prevent="moveToPage('')" id="defaultOpen1">-->
                                            <span></span>
                                            <p>
                                                <!--<a href="http://staging.safety.vtc.systems/clinics">-->
                                                {{staticLanguageMessages.front_label_menu_home}}
                                                <!-- </a>-->
                                            </p>
                                        </button>
                                        <button class="tablinks" @mouseover="openTab('home')" id="defaultOpen2" style="display:none;">
                                            <span></span>
                                            <p :title="staticLanguageMessages.front_label_menu_home"><a href="/">{{staticLanguageMessages.front_label_menu_home}}</a></p>
                                        </button>

                                        <button class="tablinks" @mouseover="openTab('home')" id="defaultOpen2" style="display:none;">
                                            <span></span>
                                            <p :title="staticLanguageMessages.front_label_menu_home"><a href="/">{{staticLanguageMessages.front_label_menu_home}}</a></p>
                                        </button>

                                        <!-- <router-link v-bind:to="{path: 'covid-19'}">
                                            <img class="linkimg" src="/static/images/covid-19.png">
                                            <div :data-title="staticLanguageMessages.front_label_coronavirus_covid19_resource" class="SectionName">
                                                <h1>{{staticLanguageMessages.front_label_coronavirus_covid19_resource}}</h1>
                                            </div>
                                        </router-link> -->

                                        <button class="tablinks" @mouseover="openTab('home')" id="defaultOpen2" >
                                            <span></span>
                                            <p :title="staticLanguageMessages.front_label_coronavirus_covid19_resource"><a href="/covid-19">{{staticLanguageMessages.front_label_coronavirus_covid19_resource}}</a></p>
                                        </button>



                                        <template v-for="(menu, index) in allMenu">
                                            <template v-if="menu.view_to_sidemenu == 1 && menu.is_active == 1">
                                                <template v-if="menu.name != 'OSHA'">
                                                    <template v-if="is_mobile">
                                                        <button :class="'tablinks ' + menu.link_url" v-on:click.prevent="moveToPage(menu.link_url)">
                                                            <span></span>
                                                            <p :title="menu.name">{{menu.name | truncate(20)}}</p>
                                                        </button>
                                                    </template>
                                                    <template v-else>
                                                        <button :class="'tablinks ' + menu.link_url" @mouseover="openTab(menu.link_url)" @mouseleave="onMouseLeave(menu.link_url)">
                                                            <span></span>
                                                            <p :title="menu.name">{{menu.name | truncate(20)}}</p>
                                                        </button>
                                                    </template>
                                                </template>
                                                <template v-else-if="(roleId == 1 || roleId == 2) && menu.name == 'OSHA'">
                                                    <template v-if="is_mobile">
                                                        <button :class="'tablinks ' + menu.link_url" v-on:click.prevent="moveToPage(menu.link_url)">
                                                            <span></span>
                                                            <p :title="menu.name">{{menu.name | truncate(20)}}</p>
                                                        </button>
                                                    </template>
                                                    <template v-else>
                                                        <button :class="'tablinks ' + menu.link_url" @mouseover="openTab(menu.link_url)" @mouseleave="onMouseLeave(menu.link_url)">
                                                            <span></span>
                                                            <p :title="menu.name">{{menu.name | truncate(20)}}</p>
                                                        </button>
                                                    </template>
                                                </template>
                                            </template>
                                        </template>

                                        <template v-if="isMenuLinkVisible">
                                            <template v-if="is_mobile">
                                                <button class="tablinks"  v-on:click.prevent="moveToPage('gallery')" >
                                                    <span></span>
                                                    <p :title="staticLanguageMessages.front_label_gallery">{{staticLanguageMessages.front_label_gallery}}</p>
                                                </button>
                                            </template>
                                            <template v-else>
                                                <button class="tablinks gallery" @mouseover="openTab('gallery')"  @mouseleave="onMouseLeave('gallery')">
                                                    <span></span>
                                                    <p :title="staticLanguageMessages.front_label_gallery">{{staticLanguageMessages.front_label_gallery}}</p>
                                                </button>
                                            </template>
                                            <button class="tablinks forum"  v-on:click.prevent="moveToPage('forum')" >
                                                <span></span>
                                                <p :title="staticLanguageMessages.front_label_menu_forum">{{staticLanguageMessages.front_label_menu_forum}}</p>
                                            </button>
                                        </template>
                                        <template v-if="loginID != 0 && !isMenuLinkVisible">
                                            <button class="tablinks calendar"  v-on:click.prevent="moveToPage('event-calendar')" >
                                                <span></span>
                                                <p :title="staticLanguageMessages.front_label_menu_calendar">{{staticLanguageMessages.front_label_menu_calendar}}</p>
                                            </button>
                                        </template>

                                    </div>
                                    <div id="home" class="tabcontent">
                                        <ul>
                                            <li>
                                                <a href="#">
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <ui1-component :allMenuData='allMenu' :key='allMenu.id'></ui1-component>
                                    <div id="gallery" class="tabcontent">
                                        <div class="PageContent">
                                            <section class="container">
                                                <div class="gallery">
                                                    <template v-for="(images, index) in imageList">
                                                        <a class="example-image-link" :href="images.image_webp" data-lightbox="header" data-title="">
                                                          <!-- <img class="example-image" :src="images.image" alt=""/> -->
                                                          <picture>
                                                            <source type="image/webp" :srcset="images.image_webp">
                                                            <source :type="images.image_type" :srcset="images.image">
                                                            <img class="example-image" :src="images.image" alt="" width="100%" height="100%">
                                                          </picture>
                                                        </a>
                                                    </template>
                                                    <!--<a class="example-image-link" href="/static/images/customgallery1.jpg" data-lightbox="example-set" data-title=""><img class="example-image" src="/static/images/customgallery1.jpg" alt=""/></a>
                                                    <a class="example-image-link" href="/static/images/customgallery2.jpg" data-lightbox="example-set" data-title=""><img class="example-image" src="/static/images/customgallery2.jpg" alt=""/></a>
                                                    <a class="example-image-link" href="/static/images/customgallery3.jpg" data-lightbox="example-set" data-title=""><img class="example-image" src="/static/images/customgallery3.jpg" alt=""/></a>
                                                    <a class="example-image-link" href="/static/images/customgallery4.jpg" data-lightbox="example-set" data-title=""><img class="example-image" src="/static/images/customgallery4.jpg" alt=""/></a>
                                                    <a class="example-image-link" href="/static/images/customgallery5.jpg" data-lightbox="example-set" data-title=""><img class="example-image" src="/static/images/customgallery5.jpg" alt=""/></a>-->
                                                </div>
                                            </section>
                                            <div class="clearfix"></div>
                                            <section class="container load_more_container">
                                                <button id="load_more_head" class="mr-2  mb-2 btn btn-danger deleteMedia pull-right" v-on:click.prevent="getImagesHeader()">{{staticLanguageMessages.front_button_label_load}}</button>
                                            </section>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="rightbg">
                            <!-- <img src="/static/images/rightbg.png"> -->
                            <picture>
                              <source type="image/webp" srcset="/static/images/rightbg.webp">
                              <source type="image/png" srcset="/static/images/rightbg.png">
                              <img src="/static/images/rightbg.png" width="235" height="339">
                            </picture>
                        </div>
                    </div>
                </div>

                <div class="CustomSiteLabel">
                    <div class="site-title">
                        <div class="site-title-text" :data-title="staticLanguageMessages.front_label_safety_zone">
                            <h1>
                                {{staticLanguageMessages.front_label_safety_zone}}
                            </h1>
                        </div>
                    </div><br/>
                    <div class="responsiveclear"></div>

                    <div class="HRZONE">
                        <a :href="hrURL" target="_blank">
                            <div class="site-title-text" :data-title="staticLanguageMessages.front_label_hr_portal">
                                <h1>
                                    {{staticLanguageMessages.front_label_hr_portal}}
                                </h1>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </section>
        <!--header-->
    </div>
</template>

<script>
    import index from "../../router";
    import EventBus from "../event-bus";
    import Vue from "vue";
    import SessioMixin from '../../mixins/sessionmixin';
    import {HTTP} from '../../http-common';
    import UI1Component from "@/components/menuuicomponents/Menu-Ui-Component";



    var self;
    import labelENMessages from '../../languages/label_en';
    import labelESMessages from '../../languages/label_es';

    export default {
        /*import * as mykey from '/static/js/jquery.rotateSlider.js',*/
        name: "Home",
        mixins: [SessioMixin],
        components: {
            'ui1-component': UI1Component,
        },
        data() {
            return {
                enIsActiveLang : true,
                esIsActiveLang : true,
                englishStaticLabelMessages: labelENMessages,
                spanishStaticLabelMessages: labelESMessages,
                staticLanguageMessages: "",
                hrURL: "",
                imageList: [],
                limit: 4,
                offset: 0,
                pullCall: 1,
                is_mobile: false,
                isLoggedIn: false,
                isShowLoggedinfields: true,
                loginID: 0,
                userPermissions: [],
                roleId: 0,
                roleName: 0,
                system_year: '',
                galleryCount: 0,
                questionsCount: 0,
                answersCount: 0,
                commentsCount: 0,
                window_width: 0,
                showEnvironmentalButton: false,
                isMenuLinkVisible : true,
                allMenu: []
            };
        },
        beforeMount: function () {
            self = this;
        },
        mounted: function () {
            let lang = localStorage.getItem('api_lang');
            if (lang != null && lang != "") {
              if(lang == 'es') {
                self.esIsActiveLang = true;
                self.enIsActiveLang = false;
                self.staticLanguageMessages = self.spanishStaticLabelMessages;
              } else {
                self.esIsActiveLang = false;
                self.enIsActiveLang = true;
                self.staticLanguageMessages = self.englishStaticLabelMessages;
              }
            } else {
              self.esIsActiveLang = false;
              self.enIsActiveLang = true;
              self.staticLanguageMessages = self.englishStaticLabelMessages;
            }
            // self.staticLanguageMessages = self.allStaticLabelMessages;
            /*alert(navigator.userAgent);*/
            self.hrURL = process.env.VUE_APP_HR_URL;
            self.hrURL = self.hrURL + "login?token=" + localStorage.getItem("api_token");
            $('#toggle').click(function () {
                $(this).toggleClass('active');
                $('#overlay').toggleClass('open');
            });
            if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
                    || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4))) {
                self.is_mobile = true;
            }

            let userObj = localStorage.getItem('user_obj');
            if (userObj != null && userObj != "") {
                EventBus.$emit('is-login', true);
                var userJSON = JSON.parse(userObj);
                self.loginID = userJSON.id;
                self.getPermissions();
            }
            self.getImagesHeader();
            // self.getMenuList();
            self.window_width = $(window).width();
            $(window).resize(function () {
                var windowWidth = $(window).width();
                if (windowWidth < 1101) {
                    if (self.window_width != windowWidth) {
                        self.window_width = windowWidth;
                    }

                }
            });


            var activeMenu = localStorage.getItem("active_menu");
            if (activeMenu != null && activeMenu != '') {
                setTimeout(function(){
                    self.menuToggle();
                    self.openTab(activeMenu);
                    localStorage.removeItem("active_menu");
                },2000);
            }
        },
        watch: {
            'window_width'(window_width) {
                //self.getMenuList();
            },
        },
        methods: {
            languageChange: function(lang) {
              console.log(lang);
              console.log('set lang2');
              localStorage.setItem('api_lang', lang);
              location.reload();
            },
            login: function () {
                self.$router.push('/login');
            },
            moveToPage(path) {
                self.$router.push('/' + path);
                self.menuToggle();
            },
            menuToggle() {
                $('#toggle').toggleClass('active');
                $('#overlay').toggleClass('open');
            },
            openTab(cityName) {
                $('.tabcontent').hide();
                $('.tablinks').removeClass('active');
                $('#' + cityName).show();
                $('.' + cityName).addClass('active');
            },
            onMouseLeave(cityName) {
                /*console.log(cityName);
                 $('.tablinks').removeClass('active');*/
            },
            getImagesHeader() {
                self = this;
                var config = {
                    headers: {Authorization: "Bearer " + localStorage.getItem("api_token")}
                };
                var fields = {
                    limit: self.limit,
                    offset: self.offset,
                    sort: 'position_sequence',
                    type: 'image',
                };
                HTTP.post("getmedia", fields, config)
                        .then(function (response) {
                            let images = response.data.content;
                            $.each(response.data.content, function (i) {
                                self.imageList.push(response.data.content[i]);
                            });
                            if (response.data.content.length == self.limit) {
                                self.offset = self.offset + self.limit;
                            } else {
                                self.pullCall = 0;
                                $("#load_more_head").hide();
                            }
                        })
                        .catch(function (err) {
                            if (err.response.status == 0) {
                                self.error = "Remote server can not be reachable";
                            } else {
                                //redirect to login page if user not authenticated
                                if (err.response.status == 401) {
                                    //clear previous data
                                    localStorage.removeItem("user_obj");
                                    localStorage.removeItem("api_token");
                                    localStorage.removeItem("profile_image");
                                    //EventBus.$emit("logged-in", true, "", "");
                                    self.$router.push("/login");
                                }
                                self.error = err.response.data.message;
                            }
                        });
            },
        }
    };
    EventBus.$on('is-login', function (isLogin) {
        if (typeof (self) != "undefined") {
            self.isLoggedIn = isLogin
        }
    })
    EventBus.$on('role', function (isLogin, id, name, system_year, userPermissions, galleryCount, questionsCount, answersCount, commentsCount) {
        if (typeof (self) != "undefined") {
            self.isShowLoggedinfields = isLogin;
            self.roleId = id;
            self.roleName = name;
            self.system_year = system_year;
            self.userPermissions = userPermissions;
            self.galleryCount = galleryCount;
            self.questionsCount = questionsCount;
            self.answersCount = answersCount;
            self.commentsCount = commentsCount;
        }
    });
    EventBus.$on('logged-in', function (isLogin, fname, lname, email) {
        if (typeof (self) != "undefined") {
            self.isShowLoggedinfields = isLogin;
            self.firstName = fname;
            self.lastName = lname;
            self.userName = email;
        }
    });
    EventBus.$on('show-environmental-button', function (data) {
        if (typeof (self) != "undefined") {
            self.showEnvironmentalButton = true;
        }
    });
    EventBus.$on('all-menu', function (allMenu) {
        if (typeof (self) != "undefined") {
            self.allMenu = allMenu;
        }
    })
</script>
