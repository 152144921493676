import EventBus from '../components/event-bus';
import {HTTP} from '../http-common';
import moment from 'moment';
import labelENMessages from '../languages/label_en';
import labelESMessages from '../languages/label_es';
export default {
//module.exports = {
    data() {
        return {
            role: {
                id: '',
                name: '',
            },
            system_year: "",
            persmission: "null",
            userPermissions: [],
            allMenu: [],
            isLoggedIn: false,
            isBackend: false,
            currentEnvironment: false,
            englishStaticLabelMessages: labelENMessages,
            spanishStaticLabelMessages: labelESMessages,
            allStaticLabelMessages: '',
        }
    },
    created() {
        /*console.log("mixin created");*/
    },
    beforeMount() {

    },
    mounted: function () {
        var self = this;
        let lang = localStorage.getItem('api_lang');
        if (lang != null && lang != "") {
          if(lang == 'es') {
            self.allStaticLabelMessages = self.spanishStaticLabelMessages;
          } else {
            self.allStaticLabelMessages = self.englishStaticLabelMessages;
          }
        } else {
          self.allStaticLabelMessages = self.englishStaticLabelMessages;
        }
        if (process.env.VUE_APP_NODE_ENV == 'production') {
            self.currentEnvironment = false;
        } else {
            self.currentEnvironment = true;
        }
        $("html, body").animate({scrollTop: 0}, 10);
        var token = localStorage.getItem("seassionToken");
        if (token === null || typeof (token) === "undefined" || token == '') {
            window.localStorage.setItem("seassionToken", self.randomString(70));
        }
        var path = window.location.pathname;
        var segments = path.split("/");
        $("body").removeClass("bodyTagColor");
        if(segments[2] == 'print'){
            $("body").addClass("bodyTagColor");
        }
    },
    methods: {
        moveToTop: function (cname, cvalue, exdays) {
            $("html, body").animate({scrollTop: 0}, 10);
        },
        setCookie: function (cname, cvalue, exdays) {
            /*var domains = ['staging.hr.vtc.systems', 'staging.safety.vtc.systems'];*/
            var d = new Date();
            d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
            var expires = "expires=" + d.toUTCString();
            document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
            /*for (var i = 0; i < domains.length; i++) {
             document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;domain=" + domains[i];
             }*/
        },
        getCookie: function (cname) {
            var name = cname + "=";
            var decodedCookie = decodeURIComponent(document.cookie);
            var ca = decodedCookie.split(';');
            for (var i = 0; i < ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0) == ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return "";
        },
        getPermissions: function () {
            var self = this;
            var config = {
                headers: {Authorization: "Bearer " + localStorage.getItem("api_token")}
            };
            /*var fields = {

             };*/
            HTTP.post("user/permission", '', config)
                    .then(function (response) {
                        if (response.data.status == 'success') {
                            self.userPermissions = [];
                            //self.allMenu = response.data.menu;
                            /*self.role.id = response.data.roles.id;*/
                            self.role.id = response.data.roles.system_id;
                            self.role.name = response.data.roles.name;
                            self.system_year = response.data.system_year;
                            $.each(response.data.permissions, function (i) {
                                //console.log(response.data.permissions[i].id + " : " + response.data.permissions[i].name);
                                self.userPermissions.push(response.data.permissions[i].name);
                                if(response.data.permissions[i].name == 'allow_environmental_data') {
                                    EventBus.$emit('show-environmental-button', true);
                                }
                            });
                            //console.log(JSON.stringify(self.allMenu));
                            /*EventBus.$emit('role', false, response.data.roles.id, response.data.roles.name, response.data.system_year, self.userPermissions);*/

                            EventBus.$emit('role', false, response.data.roles.system_id, response.data.roles.name, response.data.system_year, self.userPermissions, response.data.gallery_images_approval, response.data.question_count, response.data.answer_count, response.data.comment_count);
                        }
                    })
                    .catch(function (err) {
                        if (err.response.status == 0) {
                            self.error = "Remote server can not be reachable";
                        } else {
                            //redirect to login page if user not authenticated
                            if (err.response.status == 401) {
                                localStorage.removeItem('user_obj');
                                localStorage.removeItem('api_token');
                                self.$router.push("/login");
                            }
                        }
                    });
        },
        getMenuList: function (link_url) {
            var self = this;
            var loginRoleId = 0;
            var userObj = localStorage.getItem("user_obj");
            if (userObj != null) {
                var userJson = JSON.parse(userObj);
                loginRoleId = userJson['roles'].id;
            }
            link_url = (link_url === null || typeof (link_url) === "undefined" || link_url == '') ? '' : link_url;
            var config = {
                headers: {Authorization: "Bearer " + localStorage.getItem("api_token")},
                // params: {'is_detail':0,link_url: link_url}
                params: {'loginRoleId': loginRoleId}
            };
            HTTP.get("getmenulistdata", config)
                    .then(function (response) {
                        if (response.data.status == 'success') {
                            self.allMenu = [];
                            self.allMenu = response.data.content;
                            EventBus.$emit('all-menu', self.allMenu);

                            /* Code Started to hard refresh page after new build submitted */
                            let current_build = window.localStorage.getItem("build_version");
                            let latest_build =  response.data.content.build;
                            if(current_build == null) {
                                localStorage.setItem("build_version", data.data.content);
                            } else if(current_build != undefined && current_build == ''){
                                localStorage.setItem("build_version", response.data.content.build);
                            }else if(current_build != latest_build){
                                localStorage.setItem("build_version", response.data.content.build);
                                location.reload(true);
                            }
                            /* Code ended to hard refresh page after new build submitted */
                        }
                    })
                    .catch(function (err) {
                        if (err.response.status == 0) {
                            self.error = "Remote server can not be reachable";
                        } else {
                            //redirect to login page if user not authenticated
                            if (err.response.status == 401) {
                                localStorage.removeItem('user_obj');
                                localStorage.removeItem('api_token');
                                self.$router.push("/login");
                            }
                        }
                    });
        },
        MenuClose: function () {
            var self = this;
            $('#toggle').removeClass('active');
            $('#overlay').removeClass('open');
            $('.middle-menu').removeClass("menu-toggle")
            var expiry = localStorage.getItem("expiry");
            if (expiry != null) {
                if (new Date(expiry) < new Date()) {
                    self.logout();
                }
            } else {
                localStorage.clear();
                this.isBackend = false;
                this.$router.push("/login");
            }
        },
        checkLogin: function (redirectUrl) {
            redirectUrl = (redirectUrl === null || typeof (redirectUrl) === "undefined" || redirectUrl == '') ? 'login' : redirectUrl;
            /* Check Login */
            var check = window.localStorage.getItem("api_token");
            if (check != null && window.localStorage.getItem("api_token") != '') {
                this.isLoggedIn = true;
            } else {
                this.logout(redirectUrl);
            }
            this.persmission = "data";
            return "data";
        },

        clearLocalStorage : function() {
            localStorage.removeItem("api_token");
            localStorage.removeItem("expiry");
            localStorage.removeItem("profile_image");
            localStorage.removeItem("user_obj");
            localStorage.removeItem("access_token");
        },
        logout: function (redirectUrl) {
            var seassionToken = window.localStorage.getItem("seassionToken");
            seassionToken = (seassionToken === null || typeof (seassionToken) === "undefined" || seassionToken == '') ? '' : seassionToken;
            this.clearLocalStorage();
            window.localStorage.setItem("seassionToken", seassionToken);

            var ssoLogin = false;
            if(ssoLogin !== undefined && ssoLogin != '' && ssoLogin){
                let appSlug = process.env.VUE_APP_PROJECT_SLUG;
                let ssoUrl = process.env.VUE_APP_SSO_ROOT_URL;
                console.log("LOGIN STATUS===>",this.isLoggedIn);
                
                if(!this.isLoggedIn){
                    const redirectUrlFinal = new URL(window.location.href).origin + '/login';
                    window.location.href = `${ssoUrl}/login?redirect=${encodeURIComponent(redirectUrlFinal)}&slug=${appSlug}`;
                }else{
                    const redirectUrlFinal = new URL(window.location.href).origin;

                    EventBus.$emit('is-login', false);
                    window.location.href = `${ssoUrl}/logout?redirect_url=${encodeURIComponent(redirectUrlFinal)}&slug=${appSlug}`;
                }
            }else{
                let path = window.location.pathname;
                let segments = path.split("/");
                redirectUrl = (redirectUrl === null || typeof (redirectUrl) === "undefined" || redirectUrl == '') ? '' : redirectUrl;
                //this.setCookie('vvg_sso_auth_token', '', 365);
                EventBus.$emit('is-login', false);
                this.isBackend = false;
                if (redirectUrl == 'login') {
                    openDirectLink('/login');
                } else if (redirectUrl == '') {
                    if (segments[1] === "backend") {
                        openDirectLink('/');
                    } else if (segments[1] === "event-calendar") {
                        openDirectLink('/');
                    } else {
                        let redirectPath = '';
                        $.each(segments, function (i) {
                            if (i > 0) {
                                redirectPath += '/' + segments[i];
                            }
                        });
                        openDirectLink(redirectPath);
                    }
                } else {
                    openDirectLink("/login?redirect_url=" + redirectUrl);
                    //this.$router.push("/login?redirect_url=" + redirectUrl);
                }
            }
        },
        showLoader: function () {
            $(".loading-container").show();
        },
        hideLoader: function () {
            //setTimeout(function () {
            $(".loading-container").hide();
            //}, 1700);
            let windowHeight = $(window).height();
            let headerHeight = $(".navbar-default").outerHeight();
            let contentHeight = $("#page-content-wrapper").outerHeight();
            let footerHeight = $(".admin-footer").outerHeight();
            if ((headerHeight + contentHeight) < windowHeight) {
                /*alert(parseInt($(".admin-footer").css("marginTop").replace('px', '')));
                 alert(parseInt($(".admin-footer").css("marginBottom").replace('px', '')));*/
                /*alert("Window : " + $(window).height());
                 alert("navbar-default : " + $(".navbar-default").outerHeight());
                 alert("page-content-wrapper : " + $("#page-content-wrapper").outerHeight());
                 alert("admin-footer : " + $(".admin-footer").outerHeight());
                 /*alert("Margin will be : "+ Math.round(windowHeight -headerHeight - (contentHeight - footerHeight)-15));*/
                $(".admin-footer").css({"margin-top": Math.round(windowHeight - headerHeight - (contentHeight - footerHeight) - 10) + "px"});
            } else {
                $(".admin-footer").removeAttr("style");
            }
        },
        getUserFromToken(token, redirect) {
            var self = this;
            var config = {
                headers: {Authorization: "Bearer " + token},
                /*params: {
                 'type': self.categoryType,
                 }*/
            };
            HTTP.get("getuserdata", config)
                    .then(function (response) {
                        if (response.data.status == "success") {
                            window.localStorage.setItem("expiry", moment(new Date()).add(6, 'h').toDate());
                            window.localStorage.setItem("api_token", token);
                            window.localStorage.setItem("user_obj", JSON.stringify(response.data.content.user_obj));
                            window.localStorage.setItem("profile_image", response.data.content.user_obj.profile_image);
                            window.localStorage.setItem("app_year", response.data.content.app_year);
                            /* Code Started to hard refresh page after new build submitted */
                            let current_build = window.localStorage.getItem("build_version");
                            let latest_build =  response.data.content.build;
                            if(current_build != undefined && current_build == ''){
                                localStorage.setItem("build_version", response.data.content.build);
                            }else if(current_build != latest_build){
                                localStorage.setItem("build_version", response.data.content.build);
                                location.reload(true);
                            }

                            /* Code ended to hard refresh page after new build submitted */
                            $.each(response.data.content.permission, function (i) {
                                //console.log(response.data.permissions[i].id + " : " + response.data.permissions[i].name);
                                self.userPermissions.push(response.data.content.permissions[i].name);
                            });
                            //self.setCookie('vvg_sso_auth_token', token, 365);

                            EventBus.$emit('role', false, response.data.content.roles.system_id, response.data.content.roles.name, response.data.content.app_year, self.userPermissions, response.data.content.gallery_images_approval, response.data.content.question_count, response.data.content.answer_count, response.data.content.comment_count);
                            EventBus.$emit('logged-in',
                                    true,
                                    response.data.content.user_obj["first_name"],
                                    response.data.content.user_obj["last_name"],
                                    response.data.content.user_obj["email"],
                                    response.data.content.user_obj["profile_image"]
                                    );
                            if (redirect == 'login') {
                                openDirectLink('/');
                            }
                            //self.$router.push("/");
                        } else {
                            $("#errorMsg").html(response.data.message);
                            if (response.data.content.length > 0) {
                                var err = "";
                                err += response.data.content + "<br>";
                                $("#errorMsg").html(err);
                            }
                        }
                    })
                    .catch(function (err) {
                        //self.setCookie('vvg_sso_auth_token', '', 365);
                        $('#loginBtn').html('Login');
                        $('#loginBtn').prop('disabled', false);
                        self.Registerbtn = "Register";
                        if (err.response.status == 0) {
                            self.error = "Remote server can not be reachable";
                        } else {
                            $("#errorMsg").html(err.response.data.message);
                            if (err.response.data.content.length > 0) {
                                var err = "";
                                err += err.response.data.content + "<br>";
                                $("#errorMsg").html(err);
                            }
                        }
                        openDirectLink('/');
                    });


        },
        stringTrim: function (string, length) {
            length = (typeof length !== 'undefined') ? length : 20;
            var string1 = (string.length > length) ? string.substring(0, length) + '...' : string;
            return string1;
        },
        randomString: function (length) {
            var result = '';
            var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for (var i = 0; i < length; i++) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            return result;
        },
        utcToLocal: function (dateTime, format) {
            var self = this;
            var local = moment.utc(dateTime).local().format(format);
            return local;
        },
        formatDateTime: function (dateTime, format) {
            var self = this;
            var dateTime = new Date(dateTime);
            var dateTime = moment(dateTime).format(format);
            return dateTime;
        },
        formatString(data) {
            var self = this;
            var value = data.replace(/_/g, " ");
            value = value.toLowerCase().replace(/\b[a-z]/g, function (letter) {
                return letter.toUpperCase();
            });
            return value;
        },
        /** Convert English Word/Text to Spanish Word/Text using Below API.
            * @param word is English Word/Text to Convert that Word/Text into Spanish Word/Text
            * @param callback is to return the response to the component when the user call this method in the component
            * @return Spanish Word
        */
        convertEnglishToSpanishFunction: function(word, callback) {
            if(word.length > 0) {
                var config = {
                    headers: {Authorization: "Bearer " + localStorage.getItem("api_token")}
                };
                var fields = {
                    englishWord: word,
                };
                HTTP.post("/english/spanish/convert", fields, config)
                        .then(function (response) {
                            if (response.data.status == 'success') {
                                callback(response.data.content);
                            }
                        })
                        .catch(function (err) {
                            callback([]);
                            if (err.response.status == 0) {
                                self.error = "Remote server can not be reachable";
                            } else {
                                //redirect to login page if user not authenticated
                                if (err.response.status == 401) {
                                    localStorage.removeItem('user_obj');
                                    localStorage.removeItem('api_token');
                                    self.$router.push("/login");
                                }
                            }
                        });
            }
        },
    }
}
